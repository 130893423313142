import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import Lightbox from "../lightbox";

class P25Showcase extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <StaticQuery
                query={graphql`
                    query {
                        allFile(
                            filter: {
                                extension: { regex: "/(jpg)/" }
                                relativeDirectory: {
                                    eq: "portfolio/P2.5 Indoor"
                                }
                            }
                            sort: { fields: base, order: ASC }
                        ) {
                            nodes {
                                base
                                childImageSharp {
                                    fluid(quality: 70) {
                                        ...GatsbyImageSharpFluid
                                        originalImg
                                    }
                                }
                            }
                        }
                    }
                `}
                render={data => <Lightbox productImages={data.allFile.nodes} />}
            />
        );
    }
}

// P25Showcase.propTypes = {
//     data: PropTypes.shape({
//         allFile: PropTypes.shape({
//             filter: PropTypes.shape({
//                 extension: PropTypes.shape({
//                     regex: PropTypes.string.isRequired,
//                 }).isRequired,
//                 relativeDirectory: PropTypes.shape({
//                     eq: PropTypes.string.isRequired,
//                 }).isRequired,
//             }).isRequired,
//             sort: PropTypes.shape({
//                 field: PropTypes.string.isRequired,
//                 order: PropTypes.string.isRequired,
//             }).isRequired,
//         }).isRequired,
//     }).isRequired,
// };

export default P25Showcase;
