import React from "react";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Layout from "../../../components/layout";
import SEO from "../../../components/seo";
import ProductTable from "../../../components/productTable";
import ProductImage from "../../../images/product/indoor/P2.5/Product-Summary-P2.5-Indoor.jpg";
import OrderButton from "../../../components/orderbutton";
import P25Showcase from "../../../components/product/indoor/p25Showcase";
import Content from "../../../content/produk/indoor/P2.5/Product-Page.json";
import "../../../styles/product.css";

const p25 = ({ siteTitle }) => {

    return (
        <>
            <Layout>
                <SEO
                   title={Content.seo.title}
                   description={Content.seo.description}
                   lang={Content.seo.lang}
                />
                <section id={"produk"} className={"section-bg"}>
                    <Container>
                        <div className={"section-title"}>
                            <h1>P2.5 Indoor LED Display Module</h1>
                            <Row>
                                <Col>
                                    <Image
                                        src={ProductImage}
                                        fluid
                                        alt={"Gambar modul"}
                                    />
                                </Col>
                            </Row>
                            <Row className={"mt-5"}>
                                <Col sm={true}>
                                    <div className={"section-title"}>
                                        <h2 className={"text-center"}>
                                            Product Summary
                                        </h2>
                                    </div>
                                    <p style={{ textAlign: "justify" }}>
                                        P2.5 indoor merupakan layar LED penuh
                                        warna dengan kepadatan piksel yang
                                        sangat tinggi sehingga yang cocok
                                        digunakan pada <i>creative space</i>,{" "}
                                        <i>indoor stage</i>, <i>hall</i>, tempat
                                        belajar mengajar, acara atau kegiatan
                                        yang perlu media yang membantu
                                        penyampaian informasi dengan baik
                                        (informatif) seperti seminar,{" "}
                                        <i>meeting room</i> (ruang pertemuan)
                                        dan acara-acara dalam ruang{" "}
                                        <i>(indoor)</i> lainnya. P2.5 memiliki
                                        kualitas sebagai berikut:
                                    </p>
                                    <ul style={{ textAlign: "left" }}>
                                        <li>
                                            <i>Refresh rate</i> yang baik
                                        </li>
                                        <li>
                                            Kepadatan piksel yang tinggi
                                            sehingga menghasilkan visual yang
                                            detail dan menarik
                                        </li>
                                        <li>
                                            Media yang inovatif, interaktif,
                                            informatif untuk berbagai keperluan.
                                        </li>
                                        <li>
                                            Struktur yang kuat dan tahan lama.
                                        </li>
                                    </ul>
                                    <p>
                                        <OrderButton
                                            messageText={Content.messageText}
                                        />
                                    </p>
                                </Col>
                                <Col>
                                    <div className={"section-title"}>
                                        <h2 className={"text-center"}>
                                            Unit Module
                                        </h2>
                                    </div>
                                    <ProductTable
                                        tableName={"Unit Module"}
                                        tableData={Content.moduleUnit}
                                    />
                                </Col>
                            </Row>
                            <Row className={"mt-5"}>
                                <Col>
                                    <div className={"section-title"}>
                                        <h2 className={"text-center"}>
                                            LED Cabinet Unit
                                        </h2>
                                    </div>
                                    <ProductTable
                                        tableName={"LED Unit Cabinet"}
                                        tableData={Content.cabinetUnit}
                                    />
                                </Col>
                            </Row>
                            <Row className={"mt-5"}>
                                <Col>
                                    <div className={"section-title"}>
                                        <h2 className={"text-center"}>
                                            Contoh Pemasangan P2.5 Indoor
                                        </h2>
                                    </div>
                                    <P25Showcase />
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </section>
            </Layout>
        </>
    );
};

p25.propTypes = {
    siteTitle: PropTypes.string,
};

p25.defaultProps = {
    siteTitle: ``,
};

export default p25;
